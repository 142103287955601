export function stream() {
	//stream

	if ( $('.js-stream-switch').length > 0 )
	{

		const streamOne = window.outside1;
		const streamTwo = window.outside2;
		const streamThree = window.inside1;
		const streamFour = window.inside2;
		const streamSrc = $('#ytStream');

		$('.js-stream-switch').click(function() {
			$('.js-stream-switch').removeClass('active');
			$(this).addClass('active');

			if ( $(this).data('id') === 1 )
			{
				console.log(1);
				streamSrc.attr('src', streamOne);
				window.location.hash = 'kontoret';
				return;

			}
			if ( $(this).data('id') === 2 )
			{
				console.log(2);
				streamSrc.attr('src', streamTwo);
				window.location.hash = 'baksidan';
				return;
			}
			if ( $(this).data('id') === 3 )
			{
				console.log(3);
				streamSrc.attr('src', streamThree);
				window.location.hash = 'inne1';
				return;
			}
			if ( $(this).data('id') === 4 )
			{
				console.log(4);
				streamSrc.attr('src', streamFour);
				window.location.hash = 'inne2';
				return;
			}
		});
		if ( window.location.hash === '#kontoret' || !window.location.hash )
		{
			streamSrc.attr('src', streamOne);
			$('.js-stream-switch[data-id="1"]').addClass('active');
			return;
		}
		if ( window.location.hash === '#baksidan' )
		{
			streamSrc.attr('src', streamTwo);
			$('.js-stream-switch[data-id="2"]').addClass('active');
			return;
		}
		if ( window.location.hash === '#inne1' )
		{
			streamSrc.attr('src', streamThree);
			$('.js-stream-switch[data-id="3"]').addClass('active');
			return;
		}
		if ( window.location.hash === '#inne2' )
		{
			streamSrc.attr('src', streamFour);
			$('.js-stream-switch[data-id="4"]').addClass('active');
			return;
		}

	}
}