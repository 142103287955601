export function contactModal() {
    
    const departmenName = document.querySelectorAll('.department-name');

    departmenName.forEach(el => {
        el.addEventListener('click', () => {
            console.log('klickar')
            const targetSibling = el.nextElementSibling

            // if(targetSibling.classList.contains('hidden')) {
            //     targetSibling.classList.remove('hidden')
            //     return false
            // }
            // departmenName.forEach(event => {
            //     event.classList.remove('hidden')
            // })
            targetSibling.classList.toggle('hidden')
        })
    });

    const closeModal = document.querySelectorAll('.close-modal');

    closeModal.forEach(el => {
        el.addEventListener('click', () => {        
            const targetSibling = el.parentElement
            targetSibling.classList.toggle('hidden')
        }); 
    });

    const closeButton = document.querySelectorAll('.close-button');

    closeButton.forEach(el => {
        el.addEventListener('click', () => {
            const targetSibling = el.parentElement.parentElement
            targetSibling.classList.toggle('hidden')
        }); 
    });
}