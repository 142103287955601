export function prosHandler() {
    let overlay = $('.pros-overlay');

    $('.blue-box').click(function() {

        overlay.addClass('visible');
        $('body').addClass('of-y-hidden');

        let boxId = $(this).data('id');
        populateOverlayContent(boxId, false);


    });
    $('.js-close-pros-overlay').click(function(){
        $('body').removeClass('of-y-hidden');
        overlay.removeClass('visible');
        resetOverlayContent();

    });

    $('.js-prev-pro').click(function(){ 
        navigatePros('prev');
    });
    $('.js-next-pro').click(function(){ 
        navigatePros('next');
    });

    function navigatePros(direction) {
        let currentIndex = $('.pros-overlay-navigation').data('currentindex');
        let nextIndex = currentIndex;
        let totalIndex = $('.blue-box').length;
        setTimeout(function() {
            resetOverlayContent();

        }, 240);

        if(direction == 'next') {
            nextIndex = nextIndex +1;
            if (nextIndex > totalIndex) {
                nextIndex = 1;
            }
        }

        if(direction == 'prev') {
            nextIndex = nextIndex -1;
            if (nextIndex < 1) {
                nextIndex = totalIndex;
            }
        }

        populateOverlayContent(nextIndex, true);

    }

    function resetOverlayContent() {
        $('#overlayText').text('');
        $('#overlayImage').attr('src', '');
        $('#overlayVideo').attr('src', '').hide();
        $('.pros-overlay').css('background-image', '');
        $('.pros-overlay-filter').hide();
    }

    function populateOverlayContent(id, animated) {
        let contentBox = $('.pros-overlay-box');
        let box = $('.blue-box[data-id="'+id+'"]');
        let title = box.find('.blue-box-number').text();
        let textWrap = box.find('.blue-box-text');
        let text = box.find('.blue-box-text').html();
        let image = box.find('.blue-box-image').attr('src');
        let bgimage = box.find('.overlay').data('bg');
        let video = box.find('video').attr('src');
        $('#overlayText').removeClass('quote');
        window.location.hash = id;

        if(animated == true) {
            contentBox.css('opacity', 0).css('transform', 'translateY(100%)');
            $('.pros-overlay-navigation').data('currentindex', id);
            $('#overlayTitle').text(title);
            setTimeout(function() {
                contentBox.css('transform', 'translateY(-100%)');
                if(video) {
                    $('#overlayVideo').attr('src', video).show();
                }
                if(text) {
                    $('#overlayText').html(text);
                    if(textWrap.hasClass('quote')) {
                        $('#overlayText').addClass('quote');
                    }
                }
                $('#overlayImage').css('display', 'none');
                if(typeof(image) != 'undefined' ) {
                    $('#overlayImage').attr('src', image);
                    $('#overlayImage').css('display', 'block');
                }

            }, 240);
            setTimeout(function(){
                contentBox.css('opacity', 1).css('transform', 'translateY(0%)');

                if(bgimage) {
                    $('.pros-overlay').css('background-image', 'url(' + bgimage + ')');
                    $('.pros-overlay-filter').show();
                }
            }, 480);

        } else {

            $('.pros-overlay-navigation').data('currentindex', id);

            $('#overlayTitle').text(title);
            if(video) {
                $('#overlayVideo').attr('src', video);
            }
            if(text) {
                $('#overlayText').html(text);
                if(textWrap.hasClass('quote')) {
                    $('#overlayText').addClass('quote');
                }
            }
            $('#overlayImage').css('display', 'none');
            if(typeof(image) != 'undefined' ) {
                $('#overlayImage').attr('src', image);
                $('#overlayImage').css('display', 'block');
            }
             if(bgimage) {
                $('.pros-overlay').css('background-image', 'url(' + bgimage + ')');
                $('.pros-overlay-filter').show();
            }
        }

    }
    //Open nice overlay if hash url
    if(window.location.hash && $('body').hasClass('page-template-tpl-100')) {
        let id = window.location.href.split('#')[1];
        overlay.addClass('visible');
        $('body').addClass('of-y-hidden');
        populateOverlayContent(id, false);
    }
}