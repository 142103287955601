export function calculator() {
	const queryParams = new URLSearchParams(window.location.search)
	const areaValue = queryParams.get('area')
	const floorsValue = queryParams.get('floors')

	if (areaValue || floorsValue) {

	} else {
		calculate(5000, 5)
	}

	document.getElementById('submitBtn').addEventListener('click', function(e) {
		e.preventDefault() // Prevent form from submitting traditionally
		const areaValue = parseInt(document.getElementById('areaInput').value)
		const floorsValue = parseInt(document.getElementById('floorsInput').value)

		if (areaValue < 0 || isNaN(areaValue)) {
			document.getElementById('areaInput').style.border = '1px solid red'
		} else {
			document.getElementById('areaInput').style.border = '1px solid #1482FA'
		}

		if (floorsValue < 0 || isNaN(floorsValue)) {
			document.getElementById('floorsInput').style.border = '1px solid red'
		} else {
			document.getElementById('floorsInput').style.border = '1px solid #1482FA'
		}

		if (areaValue < 0 || floorsValue < 0 || isNaN(areaValue) || isNaN(floorsValue)) {
		} else {
			calculate(areaValue, floorsValue)
			document.querySelector('.chart-holder').scrollIntoView({ behavior: 'smooth', block: 'start' })
		}
	})

	function updateURLWithValues(areaValue, floorsValue) {
		const url = new URL(window.location.href)
		url.searchParams.set('area', areaValue)
		url.searchParams.set('floors', floorsValue)
		window.history.replaceState({}, '', url)
	}

	function getValuesFromURL() {
		const queryParams = new URLSearchParams(window.location.search)
		const areaValue = queryParams.get('area')
		const floorsValue = queryParams.get('floors')

		if (areaValue && floorsValue) {
			// Assuming you have a function to set these values and recalculate
			setCalculatorValues(areaValue, floorsValue)
			calculate(areaValue, floorsValue) // Recalculate based on these values
		}
	}

	function setCalculatorValues(areaValue, floorsValue) {
		document.getElementById('areaInput').value = areaValue
		document.getElementById('floorsInput').value = floorsValue
	}

	getValuesFromURL()

	function calculate(areaValue, floorsValue) {
		var grossTotalAreaKG = []
		var climateImpactTon = []

		var contributionFromTheGroundUp = 123.3 * (areaValue / floorsValue)
		var contributionFromTheVolumes = 103.7 * areaValue
		var contributionFromTransport = 20.1 * areaValue

		var total = contributionFromTheGroundUp + contributionFromTheVolumes + contributionFromTransport
		var carbonSink = (0.7 * total) / areaValue

		// Klimatpåverkan från genomsnittligt flerbostadshus oavsett det är byggt med trästomme eller betong*
		grossTotalAreaKG.push(298)
		//Klimatpåverkan från ett flerbostadshus från Lindbäcks
		grossTotalAreaKG.push(Math.ceil(total / areaValue))
		//Klimatpåverkan från ett flerbostadshus från Lindbäcks när hela teoretiska kolslänkan beaktas**
		grossTotalAreaKG.push(Math.ceil(total / areaValue) - carbonSink)

		//Klimatpåverkan från genomsnittligt flerbostadshus oavsett det är byggt med trästomme eller betong*
		climateImpactTon.push((298 * areaValue) / 1000)
		// Klimatpåverkan från ett flerbostadshus från Lindbäcks
		climateImpactTon.push(Math.ceil(total / 1000))
		// Klimatpåverkan från ett flerbostadshus från Lindbäcks när hela teoretiska kolslänkan beaktas**
		climateImpactTon.push(((Math.ceil(total / areaValue) - carbonSink) * areaValue) / 1000)

		updateURLWithValues(areaValue, floorsValue)
		updateCharts(grossTotalAreaKG, climateImpactTon)
	}

	function updateCharts(grossTotalAreaKG, climateImpactTon) {
		const areaCanvas = document.getElementById('areaChart').getContext('2d')
		const floorsCanvas = document.getElementById('floorsChart').getContext('2d')
		const chartHolder = document.querySelector('.chart-holder')

		// Update numbers in HTML
		document.querySelector('.js-gross-total-area-kg-1').innerHTML = Math.trunc(grossTotalAreaKG[0])
		document.querySelector('.js-gross-total-area-kg-2').innerHTML = Math.trunc(grossTotalAreaKG[1])
		document.querySelector('.js-gross-total-area-kg-3').innerHTML = Math.trunc(grossTotalAreaKG[2])

		document.querySelector('.js-saved').innerHTML = Math.trunc(
			climateImpactTon[0] - climateImpactTon[1]
		)
		document.querySelector('.js-saved-coal').innerHTML = Math.trunc(
			climateImpactTon[0] - climateImpactTon[2]
		)

		document.querySelector('.js-climate-impact-ton-1').innerHTML = Math.trunc(climateImpactTon[0])
		document.querySelector('.js-climate-impact-ton-2').innerHTML = Math.trunc(climateImpactTon[1])
		document.querySelector('.js-climate-impact-ton-3').innerHTML = Math.trunc(climateImpactTon[2])

		const commonOptions = {
			scales: { y: { beginAtZero: true } },
			plugins: { legend: { display: false } },
		}
		const backgroundColors = ['#617b91', '#1482FA', '#40634d']

		if (window.charts) {
			window.charts.area.data.datasets[0].data = grossTotalAreaKG
			window.charts.area.update()

			window.charts.floors.data.datasets[0].data = climateImpactTon
			window.charts.floors.update()
		} else {
			window.charts = {
				area: new Chart(areaCanvas, {
					type: 'bar',
					data: {
						labels: ['', '', ''],
						datasets: [
							{
								label: 'Klimatpåverkan kg CO₂/BTA',
								data: grossTotalAreaKG,
								backgroundColor: backgroundColors,
								barThickness: 128,
							},
						],
					},
					options: commonOptions,
				}),
				floors: new Chart(floorsCanvas, {
					type: 'bar',
					data: {
						labels: ['', '', ''],
						datasets: [
							{
								label: 'Klimatpåverkan ton CO₂',
								data: climateImpactTon,
								backgroundColor: backgroundColors,
								barThickness: 128,
							},
						],
					},
					options: commonOptions,
				}),
			}
		}
		chartHolder.classList.remove('opacity-0', 'h-0', 'overflow-hidden')
	}
}
