import $ from 'jquery'
import jQuery from 'jquery'

if (document.getElementById('display')) {
	window.init = {}
	window.init.infographics = require('./infographics').init()
}

// export for others scripts to use
window.$ = $
window.jQuery = jQuery
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'

var contactSearch = require('./contact-search.js')
import { employeeSearch } from './contact-page-search'
import { employeeFilter } from './contact-page-filter'
var projectFilter = require('./project-filter.js')
import { swiperHandler } from './swiperHandler.js'
import { projectSlider } from './projectSlider.js'

import { vueSlider } from './vueSlider.js'
import { menuHandler } from './menuHandler.js'
import { forms } from './forms.js'
import { mobileScrollMenu } from './mobileScrollMenu.js'
import { stream } from './stream.js'
import { articleHash } from './articleHash.js'
import { prosHandler } from './pros.js'
import { accordionHandler } from './accordionHandler.js'
import { videoModal } from './youtubeEmbedd.js'
import { contactModal } from './contacModal'
import { calculator } from './calculator'

var craftSubs = require('./craftsman-subscription')
var tenantSurvey = require('./tenantSurvey')

$(function() {
	$('.menu-item').on('mouseover', function() {
		var linkPosition = $(this)
			.find('a')
			.offset()
		var holderPosition = $(this)
			.find('.holder')
			.offset()

		if ($(this).hasClass('menu-item-has-children')) {
			$(this)
				.find('.sub-menu ul')
				.css('margin-left', linkPosition.left - holderPosition.left + 'px')
		}
	})

	if (document.getElementById('tenant-survey-app')) {
		tenantSurvey.init()
	}

	//Contact/employee search/filters
	$('.js-open-contact-search').click(function() {
		contactSearch.init()
	})

	if ($('.js-craftsman-subscription').length) {
		craftSubs.init()
	}

	if ($('.employee-list').length > 0) {
		employeeSearch()
	}

	if ($('.employee-list').length > 0) {
		employeeFilter()
	}

	if ($('.project-list').length > 0) {
		projectFilter.init()
	}

	//
	$('.js-cookie-accept').click(function() {
		// Set cookie.
		setCookie('cookieAccept', true, 30)
		$('.cookie-bar').hide()
	})

	if (!getCookie('cookieAccept')) {
		$('.cookie-bar').show()
	}

	//Initiate imported functions
	// var mySwiper = new Swiper ('.swiper-container-new', {
	//   // Optional parameters
	//   // loop: false,
	//   // effect: 'fade',

	//   // slidesPerView: 1.2,
	//   // centeredSlides: true,
	//   // If we need paginatiom
	//   // Navigation arrows
	//   navigation: {
	// 	  nextEl: '.swiper-button-next-slide',
	// 	  prevEl: '.swiper-button-prev-slide',
	// 	},

	// })

	swiperHandler()
	vueSlider()

	videoModal()

	menuHandler()

	forms()

	mobileScrollMenu()

	stream()

	articleHash()

	prosHandler()

	accordionHandler()

	if (document.querySelector('.project-slider')) {
		projectSlider()
	}

	if (document.getElementById('floorsInput')) {
		calculator()
	}

	if (document.querySelector('.department-name')) {
		contactModal()
	}

	if (detectIE()) {
		$('body').addClass('ie')
		$('.grid-layout').addClass('clearfix')
	}
	var browser = get_browser()

	if (browser.name == 'Safari' && browser.version <= 10.2) {
		$('body').addClass('safariold')
		$('.grid-layout').addClass('clearfix')
	}
})
function detectIE() {
	var ua = window.navigator.userAgent

	var msie = ua.indexOf('MSIE ')
	if (msie > 0) {
		// IE 10 or older => return version number
		return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
	}

	var trident = ua.indexOf('Trident/')
	if (trident > 0) {
		// IE 11 => return version number
		var rv = ua.indexOf('rv:')
		return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
	}

	var edge = ua.indexOf('Edge/')
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
	}

	// other browser
	return false
}

function get_browser() {
	var ua = navigator.userAgent,
		tem,
		M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || []
		return { name: 'IE', version: tem[1] || '' }
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR|Edge\/(\d+)/)
		if (tem != null) {
			return { name: 'Opera', version: tem[1] }
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
		M.splice(1, 1, tem[1])
	}
	return {
		name: M[0],
		version: M[1],
	}
}
//Check if array contains value
Array.prototype.contains = function(obj) {
	var i = this.length
	while (i--) {
		if (this[i] == obj) {
			return true
		}
	}
	return false
}

function setCookie(name, value, days) {
	var expires = ''
	if (days) {
		var date = new Date()
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
		expires = '; expires=' + date.toUTCString()
	}
	document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

function getCookie(name) {
	var nameEQ = name + '='
	var ca = document.cookie.split(';')
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i]
		while (c.charAt(0) == ' ') c = c.substring(1, c.length)
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
	}
	return null
}
// var ctx1 = document.getElementById('climateImpactChart').getContext('2d')
// var climateChart1 = new Chart(ctx1, {
// 	type: 'bar',
// 	data: {
// 		labels: ['', '', ''],
// 		datasets: [
// 			{
// 				label: 'Klimatpåverkan kg CO₂/BTA',
// 				barThickness: 128,
// 				data: [298, 155, 46],
// 				backgroundColor: ['#4A4A4A', '#1482FA', '#2F5743'],
// 			},
// 		],
// 	},
// 	options: {
// 		scales: {
// 			y: {
// 				beginAtZero: true,
// 			},
// 		},
// 		plugins: {
// 			legend: {
// 				display: false,
// 			},
// 			tooltip: {
// 				enabled: false,
// 			},
// 		},
// 	},
// })

// var ctx2 = document.getElementById('climateChart').getContext('2d')
// var climateChart2 = new Chart(ctx2, {
// 	type: 'bar',
// 	data: {
// 		labels: ['', '', ''],
// 		datasets: [
// 			{
// 				label: 'Klimatpåverkan kg CO₂/BTA',
// 				barThickness: 128,
// 				data: [298, 155, 46],
// 				backgroundColor: ['#4A4A4A', '#1482FA', '#2F5743'],
// 			},
// 		],
// 	},
// 	options: {
// 		scales: {
// 			y: {
// 				beginAtZero: true,
// 			},
// 		},
// 		plugins: {
// 			legend: {
// 				display: false,
// 			},
// 			tooltip: {
// 				enabled: false,
// 			},
// 		},
// 	},
// })
