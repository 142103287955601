export function employeeFilter() {

	const filters = document.querySelectorAll('.js-filter');
	const norrBtn = document.querySelector('.js-norr');
	const malardalenBtn = document.querySelector('.js-malardalen');

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const salesTeam = urlParams.get('salesteam');

	if (salesTeam != null) {
		let selectedFilter = salesTeam;
		let itemsToHide = document.querySelectorAll(`.list .employee-item:not([data-filter='${selectedFilter}'])`);
		let itemsToShow = document.querySelectorAll(`.list [data-filter='${selectedFilter}']`);
		let itemsToAlwaysShow = document.querySelectorAll('[data-filter="both"]');

		itemsToHide.forEach(el => {
			el.classList.add('employee_filter_hide');
			el.classList.remove('employee_filter_show');
		});

		itemsToShow.forEach(el => {
			el.classList.remove('employee_filter_hide');
			el.classList.add('employee_filter_show');
		});

		itemsToAlwaysShow.forEach(el => {
			el.classList.remove('employee_filter_hide');
			el.classList.add('employee_filter_show');
		});

		filters.forEach(filter => {
			filter.classList.remove('blue-btn');
			filter.classList.add('gray-btn');
		});

		if (salesTeam === 'norr') {
			norrBtn.classList.remove('gray-btn');
			norrBtn.classList.add('blue-btn');
		} else {
			malardalenBtn.classList.remove('gray-btn');
			malardalenBtn.classList.add('blue-btn');
		};

	};

	filters.forEach(filter => {
		filter.addEventListener('click', function () {

			filters.forEach(filter => {
				filter.classList.remove('blue-btn');
				filter.classList.add('gray-btn');
			})

			this.classList.remove('gray-btn');
			this.classList.add('blue-btn');

			let selectedFilter = filter.getAttribute('data-filter');
			let itemsToHide = document.querySelectorAll(`.list .employee-item:not([data-filter='${selectedFilter}'])`);
			let itemsToShow = document.querySelectorAll(`.list [data-filter='${selectedFilter}']`);
			let itemsToAlwaysShow = document.querySelectorAll('[data-filter="both"]');

			if (selectedFilter == 'all') {
				itemsToHide = [];
				itemsToShow = document.querySelectorAll('.list [data-filter]');
			}

			itemsToHide.forEach(el => {
				el.classList.remove('employee_filter_show');
				el.classList.add('employee_filter_hide');
			});

			itemsToShow.forEach(el => {
				el.classList.remove('employee_filter_hide');
				el.classList.add('employee_filter_show');
			});

			itemsToAlwaysShow.forEach(el => {
				el.classList.remove('employee_filter_hide');
				el.classList.add('employee_filter_show');
			});

		});
	});

}