var List = require('list.js');
module.exports = new function() {

	$('.js-toggle-project-filter').click(function() {
		$(this).toggleClass('active');
		$('.filter').toggleClass('visible');
	});


	//List.js settings
	var options = {
		valueNames: [
			{ data: ['cat'] },
			{ data: ['type'] },
			{ data: ['loc'] }
		]
	};


	//Remove item with matching value from array
	function remove(arr, what) {
		var found = arr.indexOf(what);

		while (found !== -1) {
			arr.splice(found, 1);
			found = arr.indexOf(what);
		}
	}

	function init() {

		const projectList = new List('project-list', options);

		//Project type filter
		let projectValues = [];
		$('.js-project-filter').change(function() {

			let value = $(this).val();

			if ( !$(this).is(':checked') )
			{
				remove(projectValues, value);
			}
			else
			{
				$('.js-project-filter:checked').map(function(index){

					projectValues.push(value);

				});
			}

			projectValues = $.unique(projectValues);

			projectList.filter(function(item) {

				if ( projectValues.contains(item.values().cat) || projectValues.contains(item.values().type))
				{
					return item;
				}
				if ( !projectValues.length && locationValues.contains(item.values().loc) ) {
					return item;
				}
				if ( !projectValues.length && !locationValues.length )
				{
					projectList.filter(); //Empty filter returns unfiltered list.
				}
				if ( $('.list a').length == 0)
				{
					$('#noResult').show();
				}
				else
				{
					$('#noResult').hide();
				}

			});

		});
		//Project location filter
		let locationValues = [];
		$('.js-location-filter').change(function() {

			let value = $(this).val();

			if ( !$(this).is(':checked') )
			{
				remove(locationValues, value);
			}
			else
			{
				$('.js-location-filter:checked').map(function(index){

					locationValues.push(value);

				});
			}

			locationValues = $.unique(locationValues);

			projectList.filter(function(item) {
				if ( locationValues.contains(item.values().loc) && projectValues.contains(item.values().cat) || locationValues.contains(item.values().loc) && projectValues.contains(item.values().type) )
				{
					return item;
				}
				if ( locationValues.contains(item.values().loc))
				{
					if ( projectValues.length && !projectValues.contains(item.values().type) || projectValues.length && !projectValues.contains(item.values().cat) )
					{
						return false;
					}
					return item;
				}
				if ( !locationValues.length && projectValues.contains(item.values().cat) || !locationValues.length && projectValues.contains(item.values().type ) )
				{
					return item;
				}
				if ( !locationValues.length && !projectValues.length )
				{
					projectList.filter(); //Empty filter returns unfiltered list.
				}
			});


		});
		projectList.on('filterComplete', function(){
			$('#noResultProj').hide();
			if ( $('.list a').length == 0)
			{
				$('#noResultProj').show();
			}

		});
		$('.js-clear-all-filters').click(function(){
			projectList.filter();
			projectValues = [];
			locationValues = [];
			$('input:checkbox').prop('checked', false);
		});

	}

	return {
		init: function() {
			init();
		}
	}
}