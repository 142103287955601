module.exports = {

	init()
	{
		let Vue = require('vue');
		let VueResource = require('vue-resource');
		Vue.use(VueResource);

		require('./infographicsSlider').init();

		let app = new Vue({
			el: '#display',

			data: {
				response: '',
				workdayMinute: 0,
				totalWorkdayMinutes: 480,

				used: {
					color: 0,
					wood: 0,
					screws: 0,
				},

				made: {
					apartments: 0,
					modules: 0,
					homes: 0,
				},

				coworkers: {
					total: 0,
					women: 0,
					men: 0,
				},

				projects: {},

				pins: {},

				power: {
					today: 0,
					total: 0
				},

				currentSlide: 0,
				numberOfSlides: 8
			},

			methods: {

				getData(callback)
				{
					this.$http.get('/wp-json/lindbacks/v1/infographic', {})
						.then(response => {
						var data = response.body;

						this.response = data;
						callback();
					});
				},

				updateColor(){
					var totalAmount = this.response.daily.color;
					var usedAmount = (totalAmount / this.totalWorkdayMinutes) * this.workdayMinute
					this.used.color = Math.round(usedAmount);
				},

				updateScrews(){
					var totalAmount = this.response.daily.screws;
					var usedAmount = (totalAmount / this.totalWorkdayMinutes) * this.workdayMinute
					this.used.screws = Math.round(usedAmount);
				},

				updateWood(){
					var totalAmount = this.response.daily.wood;
					var usedAmount = (totalAmount / this.totalWorkdayMinutes) * this.workdayMinute
					this.used.wood = Math.round(usedAmount);
				},

				updateCoworkers(){
					var totalAmount = this.response.coworkers.total;
					var totalWomen = this.response.coworkers.women;
					var percentWomen = Math.round((totalWomen / totalAmount) * 100);
					var percentMen = 100 - percentWomen;

					this.coworkers.total = totalAmount;
					this.coworkers.men = percentMen;
					this.coworkers.women = percentWomen;
				},

				updateMade(){
					var apartments = (this.response.daily.apartments / this.totalWorkdayMinutes) * this.workdayMinute;
					var modules = (this.response.daily.modules / this.totalWorkdayMinutes) * this.workdayMinute;

					this.made.apartments = Math.round(apartments);
					this.made.modules = Math.round(modules);
					this.made.homes = this.response.daily.homes;
				},

				updateWorkdayMinute()
				{
					var workdayStart = 7;
					var now = new Date();
					var today = new Date(now.getFullYear(),now.getMonth(),now.getDate(), workdayStart,0,0);
					var workdayMinute =  (now - today) / 1000 / 60;

					this.workdayMinute = workdayMinute;
				},

				updateProjects()
				{
					this.projects = this.response.projects;
					this.numberOfSlides = this.projects.length;
				},

				updatePins()
				{
					this.pins = this.response.pins;
				},

				updatePower()
				{
					this.power = this.response.power;
				},

				updateValues(){
					var _this = this;
					_this.updateWorkdayMinute();

					_this.getData(function(){
						_this.updateColor();
						_this.updateScrews();
						_this.updateWood();
						_this.updateCoworkers();
						_this.updateMade();
						_this.updateProjects();
						_this.updatePins();
						_this.updatePower();
					});
				},

				cycleSlide(){
					var _this = this;
					setInterval(function(){

						if( _this.currentSlide < (_this.numberOfSlides - 1 ) ){
							_this.currentSlide = _this.currentSlide + 1;
						} else {
							_this.currentSlide = 0;
						}

					}.bind(this), 10000);

				}
			},

			mounted: function () {

			    setInterval(function () {

			      this.updateValues();


			    }.bind(this), 10000);

				this.updateValues();
			    this.cycleSlide();
			},

		});
	}

};