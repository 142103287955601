module.exports = {
	init: function() {
		$('.js-craftsman-subscription').submit(function(e) {

			$('.js-loading').show();

			var $form = $(this);
			var id = $(this).attr('data-postid');

			$.ajax({
				type: 'POST',
				url: '/wp-json/lindbacks/craftsman-subscriptions',
				dataType: 'json',
				contentType: 'application/json',
				data: JSON.stringify({
					post_id: id,
					name: $(this).find('#subscription_name').val(),
					phone: $(this).find('#subscription_phone').val(),
					email: $(this).find('#subscription_email').val(),
					object: $(this).find('#subscription_object').val(),
					apartment: $(this).find('#subscription_apartment').val(),
					message: $(this).find('#subscription_message').val(),
					time: $(this).find('#subscription_time').val()
				})
			})
			.done(function(data) {
				$('.js-loading').hide();
				$('.js-subscription-success').show();
				$('.js-craftsman-form').hide();
			})
			.fail(function(data) {
				$('.js-loading').hide();

				$form.find('.js-subscription-errors').html('');

				if (data.status === 422) {
					var errors = data.responseJSON.error;

					for (var field in errors) {
						$form.find('.js-subscription-errors').append('<p>'+errors[field]+'</p>');
					}
				}

				if (data.status === 500) {

				}
			});

			e.preventDefault();
		});
	}
}