module.exports = {

	init()
	{
		var Vue = require('vue');


		Vue.component('slider', {

			props: [
				'pins',
				'projects',
				'currentSlide',
			],

			methods: {

				isActive(index)
				{
					return index == this.currentSlide ? true : false;
				}

			},

			template: `
				<article class="map-slider">
					<div class="slider" v-for="(project, index) in projects" v-if="isActive(index)">
						<div class="slide">
							<img class="main-image" :src="project.image"/>

							<div class="meta-holder">
								<h3>{{project.name}}</h3>
								<p><strong>Antal lägenheter/</strong>Number of apartments:<br>
								{{project.number_of_apartments}}</p>
								<p><strong>Var/</strong>Where:<br> {{project.plats}}</p>
								<p><strong>Läs mer/</strong>More information:<br> {{project.url}}</p>
							</div>
						</div>
					</div>

					<article class="map">
						<div class="nail" v-for="(pin, index) in pins" :style="{left: pin.x + 'px', top: pin.y + 'px'}" :class="'key-' + index">
							<span>{{pin.slug}}</span>
						</div>
					</article>
				</article>
			`
		});
	}
};