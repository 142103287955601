module.exports = {

	init()
	{
		let Vue = require('vue');
		let draggable = require('vuedraggable');
		let VueResource = require('vue-resource');
		Vue.use(VueResource);

		const tenantSurvey = {
			props: ['options', 'segments'],
			components: { draggable },
			data: function() {
				return {
					currentStep: 1,
					selectedOptions: [],
					selectedSegment: 'Medarbetare',
					errorMessage: null,
					surveyComplete: false,
				};
			},
			computed: {
				randomOrderOptions: function() {
					return this.shuffleArray(this.options);
				}
			},
			methods: {
				nextStep: function() {
					this.errorMessage = null;
					if (this.currentStep === 1)
					{
						if (this.selectedOptions.length !== 5) {
							this.errorMessage = 'Välj fem frågor';
							return;
						}
						this.currentStep = 2;
					}
					else if (this.currentStep === 2)
					{
						this.currentStep = 0;
						this.surveyComplete = true;
						this.submitSurvey();
					}
				},
				submitSurvey: function() {
					const data = {
						options: this.selectedOptions,
						segment: this.selectedSegment,
					};

					this.$http.post('/wp-json/lindbacks/tenant-survey-result', data)
						.then(response => {
							console.log('mm', response);
						});
				},
				shuffleArray: function(array) {
					let currentIndex = array.length, temporaryValue, randomIndex;

					while (0 !== currentIndex) {
						randomIndex = Math.floor(Math.random() * currentIndex);
						currentIndex -= 1;
						temporaryValue = array[currentIndex];
						array[currentIndex] = array[randomIndex];
						array[randomIndex] = temporaryValue;
					}

					return array;
				}
			},
			template: `
				<div>
					<div v-if="currentStep === 1" class="options">
						<h3 class="step-title">Vilka av de 20 viktiga hållbarhetsfrågor här nedanför tycker du, som medarbetare, att Lindbäcks kan bidra mest till och därför bör fokusera sitt hållbarhetsarbete på? Välj fem frågor.</h3>
						<label v-for="option in randomOrderOptions" class="survey-checkbox-wrap">
							<input type="checkbox" name="option_checkbox" :value="option" v-model="selectedOptions" /> {{ option }}
						</label>
					</div>

					<div v-if="currentStep === 2">
						<h2 class="step-title">Rangordna frågorna nedan efter hur viktiga du tycker de är. Dra och släpp för att byta ordning</h2>
						<draggable element="div" v-model="selectedOptions">
							<transition-group type="transition" :name="'flip-list'">
								<li class="draggable-item" v-for="option in selectedOptions" :key="option">
									{{ option }}
								</li>
							</transition-group>
						</draggable>
					</div>

					<div v-if="surveyComplete">
						<h2 class="step-title">Tack!</h2>
						<p></p>
					</div>

					<div class="frm_submit tentant-survey" v-if="surveyComplete === false">
						<span v-if="currentStep > 1" style="margin-right: 10px;">
							<input type="submit" v-on:click="currentStep -= 1" value="Föregående" />
						</span>
						<input type="submit" v-on:click="nextStep()" :value="currentStep === 2 ? 'Slutför' : 'Nästa'" />
					</div>

					<div v-if="errorMessage" class="form-error-message">{{ errorMessage }}</div>
				</div>
			`
		};

		let app = new Vue({
			el: '#tenant-survey-app',
			data: function() {
				return {};
			},
			components: {
				'tenant-survey': tenantSurvey
			},
			methods: {},
		});
	}
};